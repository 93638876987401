// action constants
import Vue from 'vue'
import { StoreMutations } from './mutations';
import SwiftSpaceRepository from '../../../../api/SwiftSpaceRepository';

export const StoreActions = {
    getRecent:'getRecent',
    clearState:'clearState'
};

export const actions = {
    [StoreActions.getRecent](context) {
        SwiftSpaceRepository.recentBackgrounds()
        .then((data) => {
            context.commit(StoreMutations.SET_RESULTS,data.backgrounds)
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    clearState(context) {
        context.commit(StoreMutations.SET_RESULTS,[]);
    }
};
