<template>
  <div class="uiv">
    <navbar>
      <a class="navbar-brand" slot="brand" href="#">
      </a>
      <template slot="collapse">
        <navbar-nav>
          <li>
            <router-link role="button" :to="'/'">Home</router-link>
          </li>

          <Dropdown tag="li">
            <a class="dropdown-toggle" role="button"
            >Acronyms <span class="caret"></span
            ></a>
            <template #dropdown>
              <li>
                <router-link role="button" :to="'/acronyms'">
                  Search
                </router-link>
              </li>
            </template>
          </Dropdown>

          <Dropdown tag="li">
            <a class="dropdown-toggle" role="button"
            >Awards <span class="caret"></span
            ></a>
            <template #dropdown>
              <li>
                <router-link role="button" :to="'/awards/answers/recent'">
                  Answers
                </router-link>
              </li>
              <li>
                <router-link role="button" :to="'/awards/search'">
                  Search
                </router-link>
              </li>
            </template>
          </Dropdown>

          <Dropdown tag="li">
            <a class="dropdown-toggle" role="button"
            >Backgrounds <span class="caret"></span
            ></a>
            <template #dropdown>
              <li>
                <router-link role="button" :to="'/backgrounds/flagged'">
                  Flagged
                </router-link>
              </li>
              <li>
                <router-link role="button" :to="'/backgrounds/recent'">
                  Recent
                </router-link>
              </li>
            </template>
          </Dropdown>

          <Dropdown tag="li">
            <a class="dropdown-toggle" role="button"
            >Businesses <span class="caret"></span
            ></a>
            <template #dropdown>
              <li>
                <router-link role="button" :to="'/businesses/pending'">
                  Pending Businesses
                </router-link>
              </li>
              <li>
                <router-link role="button" :to="'/businesses/reported'">
                  Reported Businesses
                </router-link>
              </li>
              <li>
                <router-link role="button" :to="'/businesses/search'">
                  Search
                </router-link>
              </li>
            </template>
          </Dropdown>

          <Dropdown tag="li">
            <a class="dropdown-toggle" role="button"
            >Events <span class="caret"></span
            ></a>
            <template #dropdown>
              <li>
                <router-link role="button" :to="'/events/pending'">
                  Pending Events
                </router-link>
              </li>
              <li>
                <router-link role="button" :to="'/events/reported'">
                  Reported Events
                </router-link>
              </li>
              <li>
                <router-link role="button" :to="'/events/search'">
                  Search
                </router-link>
              </li>
            </template>
          </Dropdown>

          <Dropdown tag="li">
            <a class="dropdown-toggle" role="button"
            >Me <span class="caret"></span
            ></a>
            <template #dropdown>
              <li>
                <router-link role="button" :to="'/me/flagged'">
                  Flagged
                </router-link>
              </li>
              <li>
                <router-link role="button" :to="'/me/search'">
                  Search
                </router-link>
              </li>
              <li>
                <router-link role="button" :to="'/me/reported'">
                  Reported Me
                </router-link>
              </li>
            </template>
          </Dropdown>

          <Dropdown tag="li">
            <a class="dropdown-toggle" role="button"
            >Playlists <span class="caret"></span
            ></a>
            <template #dropdown>
              <li>
                <router-link role="button" :to="'/playlists'">
                  Search
                </router-link>
              </li>
            </template>
          </Dropdown>

          <Dropdown tag="li">
            <a class="dropdown-toggle" role="button"
            >Posts <span class="caret"></span
            ></a>
            <template #dropdown>
              <li>
                <router-link role="button" :to="'/posts'">
                  Posts
                </router-link>
              </li>
            </template>
          </Dropdown>

          <Dropdown tag="li">
            <a class="dropdown-toggle" role="button"
            >Statuses <span class="caret"></span
            ></a>
            <template #dropdown>
              <li>
                <router-link role="button" :to="'/statuses'">
                  Statuses
                </router-link>
              </li>
            </template>
          </Dropdown>

          <Dropdown tag="li">
            <a class="dropdown-toggle" role="button"
            >Themes <span class="caret"></span
            ></a>
            <template #dropdown>
              <li>
                <router-link role="button" :to="'/themes'">
                  Search
                </router-link>
              </li>
            </template>
          </Dropdown>

          <Dropdown tag="li">
            <a class="dropdown-toggle" role="button"
            >Users <span class="caret"></span
            ></a>
            <template #dropdown>
              <li>
                <router-link role="button" :to="'/users/locations'">
                  Map
                </router-link>
              </li>
              <li>
                <router-link role="button" :to="'/users/reported'">
                  Reported Users
                </router-link>
              </li>
            </template>
          </Dropdown>

          <Dropdown tag="li">
            <a class="dropdown-toggle" role="button"
            >Wallpapers <span class="caret"></span
            ></a>
            <template #dropdown>
              <li>
                <router-link role="button" :to="'/wallpapers/pending'">
                  Pending
                </router-link>
              </li>
              <li>
                <router-link role="button" :to="'/wallpapers/reported'">
                  Reported
                </router-link>
              </li>
              <li>
                <router-link role="button" :to="'/wallpapers'">
                  Search
                </router-link>
              </li>
            </template>
          </Dropdown>

          <li>
            <a @click="logout" href="#">Logout</a>
          </li>
        </navbar-nav>
      </template>
    </navbar>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {};
  },
  mounted() {
  },
  methods: {
    logout() {
      console.log("log out")
      this.$destroySession()
      this.$router.push('/login')
    }
  },
  computed: {
    routes() {
      return [
        {
          name: "Home",
          link: "/home"
        }
      ]
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar-default {
  height: 75px;
}

li {
  font-size: 12pt;
  padding-top: 10px;
}

@media (max-width: 767px) {
  .navbar-nav {
    background: #efefef;
  }

  .navbar {
    z-index: 2;
  }
}
</style>
