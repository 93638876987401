import APIConnection from '@/api/APIConnection';
export default {
    stats(){
        var url = `${APIConnection.v2Url}/stats`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    reportdUsers() {
        var url = `${APIConnection.v2Url}/users/reported`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    reviewedUser(data) {
        var url = `${APIConnection.v2Url}/users/reported/reviewed`;
        return APIConnection.post(url, data, APIConnection.v2Interceptor);
    },
    getUser(id) {
        var url = `${APIConnection.v2Url}/users/${id}`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    timeout(id) {
        var url = `${APIConnection.v2Url}/users/${id}/timeout`;
        return APIConnection.post(url, {}, APIConnection.v2Interceptor);
    },
    superFan(id) {
        var url = `${APIConnection.v2Url}/users/${id}/superFan`;
        return APIConnection.post(url, {}, APIConnection.v2Interceptor);
    },
    restrict(id) {
        var url = `${APIConnection.v2Url}/users/${id}/restrict`;
        return APIConnection.post(url, {}, APIConnection.v2Interceptor);
    },
    underage(id) {
        var url = `${APIConnection.v2Url}/users/${id}/underage`;
        return APIConnection.post(url, {}, APIConnection.v2Interceptor);
    },
    reportedBusinesses() {
        var url = `${APIConnection.v2Url}/businesses/reported/pending`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    reviewReportedBusiness(id, data) {
        var url = `${APIConnection.v2Url}/businesses/reported/${id}`;
        return APIConnection.post(url, data, APIConnection.v2Interceptor);
    },
    reportedEvents() {
        var url = `${APIConnection.v2Url}/events/reported/pending`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    reviewReportedEvent(id, data) {
        var url = `${APIConnection.v2Url}/events/reported/${id}`;
        return APIConnection.post(url, data, APIConnection.v2Interceptor);
    },
    reportedMes() {
        var url = `${APIConnection.v2Url}/me/reported/pending`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    reviewReportedMe(id, data) {
        var url = `${APIConnection.v2Url}/me/reported/${id}`;
        return APIConnection.post(url, data, APIConnection.v2Interceptor);
    },
    flaggeddMes() {
        var url = `${APIConnection.v2Url}/me/flagged`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    reviewFlaggedMe(id, data) {
        var url = `${APIConnection.v2Url}/me/flagged/${id}/review`;
        return APIConnection.post(url, data, APIConnection.v2Interceptor);
    },
    pendingBusinesses() {
        var url = `${APIConnection.v2Url}/businesses/review/pending`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    reviewBusiness(id, data) {
        var url = `${APIConnection.v2Url}/businesses/review/${id}`;
        return APIConnection.post(url, data, APIConnection.v2Interceptor);
    },
    pendingEvents() {
        var url = `${APIConnection.v2Url}/events/review/pending`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    reviewEvent(id, data) {
        var url = `${APIConnection.v2Url}/events/review/${id}`;
        return APIConnection.post(url, data, APIConnection.v2Interceptor);
    },
    getEvent(id) {
        var url = `${APIConnection.v2Url}/events/${id}`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    getBusiness(id) {
        var url = `${APIConnection.v2Url}/businesses/${id}`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    getMe(id) {
        var url = `${APIConnection.v2Url}/me/${id}`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    searchEvents(page) {
        var url = `${APIConnection.v2Url}/events/search?page=${page}`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    searchBusinesses(page) {
        var url = `${APIConnection.v2Url}/businesses/search?page=${page}`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    searchMe(page) {
        var url = `${APIConnection.v2Url}/me/search?page=${page}`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    searchAwardAnswers(page) {
        var url = `${APIConnection.v2Url}/awards/answers/recent?page=${page}`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    searchAwards() {
        var url = `${APIConnection.v2Url}/awards`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    createAward(form) {
        var url = `${APIConnection.v2Url}/awards`;
        return APIConnection.post(url, form, APIConnection.v2Interceptor);
    },
    updateAward(form) {
        var url = `${APIConnection.v2Url}/awards/${form.id}`;
        return APIConnection.put(url, form, APIConnection.v2Interceptor);
    },
    searchThemes() {
        var url = `${APIConnection.v2Url}/themes`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    updateThemeFeatured(id, data) {
        var url = `${APIConnection.v2Url}/themes/${id}/featured`;
        return APIConnection.patch(url, data, APIConnection.v2Interceptor);
    },
    searchStatuses() {
        var url = `${APIConnection.v2Url}/statuses`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    searchAcronyms() {
        var url = `${APIConnection.v2Url}/acronyms`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    updateAcronym(id, data) {
        var url = `${APIConnection.v2Url}/acronyms/${id}`;
        return APIConnection.put(url, data, APIConnection.v2Interceptor);
    },
    deleteAcronym(id) {
        var url = `${APIConnection.v2Url}/acronyms/${id}`;
        return APIConnection.delete(url, {}, APIConnection.v2Interceptor);
    },
    searchPlaylists() {
        var url = `${APIConnection.v2Url}/playlists`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    updatePlaylist(id, data) {
        var url = `${APIConnection.v2Url}/playlists/${id}`;
        return APIConnection.put(url, data, APIConnection.v2Interceptor);
    },
    searchWallpapers() {
        var url = `${APIConnection.v2Url}/wallpapers`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    getPendingWallpapers() {
        var url = `${APIConnection.v2Url}/wallpapers/pending`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    reportedWallpapers() {
        var url = `${APIConnection.v2Url}/wallpapers/reported`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    updateWallpaper(id, data) {
        var url = `${APIConnection.v2Url}/wallpapers/${id}`;
        return APIConnection.put(url, data, APIConnection.v2Interceptor);
    },
    createPost(form) {
        var url = `${APIConnection.v2Url}/posts`;
        return APIConnection.post(url, form, APIConnection.v2Interceptor);
    },
    usersWithLocation(lat, lng, distance, page) {
        var url = `${APIConnection.v2Url}/users/locations`;
        return APIConnection.post(url, {lat: lat, lng: lng, distance: distance, page: page}, APIConnection.v2Interceptor);
    },
    recentBackgrounds() {
        var url = `${APIConnection.v2Url}/backgrounds/recent`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    flaggedBackgrounds() {
        var url = `${APIConnection.v2Url}/backgrounds/flagged`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    reviewFlaggedBackground(id, data) {
        var url = `${APIConnection.v2Url}/backgrounds/flagged/${id}/review`;
        return APIConnection.post(url, data, APIConnection.v2Interceptor);
    },

}